<template>
    <v-radio
        class="base-radio"
        v-bind="$attrs"
        v-on="$listeners"
        color="primary"
        hide-details
    >
        <template :slot="slot" v-for="(scope, slot) in $slots">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </v-radio>
</template>

<script>
    export default {
        name: 'BaseRadio',
        inheritAttrs: false
    }
</script>

<style lang="scss">
.base-radio {
    background: #efefef;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    .v-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>
