<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-checkbox
        class="base-checkbox"
        v-on="$listeners"
        v-bind="$attrs"
        color="primary"
        hide-details
    >
        <template :slot="slot" v-for="(scope, slot) in $slots">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </v-checkbox>
</template>

<script>
    export default {
        name: 'base-checkbox',
        inheritAttrs: false,
        model: {
            prop: 'input-value',
            event: 'change'
        },
        props: {
            type: null
        }
    }
</script>

<style lang="scss">
.base-checkbox {
    background: #efefef;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    .v-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
</style>
